import React from "react";
import NumberFollowers from "../NumberFollowers/NumberFollowers";

const About = ({ name, description, imageAbout, followers, aboutTitle }) => {
  return (
    <header className="about freelancre valign">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 text-center mt-5">
            {imageAbout &&
              <div
                className="img wow fadeInUp"
                data-wow-delay=".6s"
             >
                <img src={imageAbout} alt="" />
              </div>
            }
            <div className="states mt-5 ">
              <div className="container text-center">
                <ul>
                  <li className="flex">
                    <div className="numb color-font valign">
                      <h6>
                        <a href={`https://instagram.com/${name}`} target="_blank">@{name}</a>
                      </h6>
                    </div>
                  </li>
                  { followers &&
                    <li className="flex">
                      <div className="numb color-font valign mt-2">
                        <h4><NumberFollowers number={followers} /></h4>
                        <p>
                          Seguidores
                        </p>
                      </div>
                    </li>
                  }
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-8 valign">
            <div className="cont sec-head mb-0">
              
              <h3 className="ls1 mb-30 color-font">
                {aboutTitle}
              </h3>
              <p>{description}</p>
            </div>
          </div>
        </div>

        
      </div>
      <div className="line bottom left"></div>
    </header>
  );
};

export default About;
