import React from "react";

const WhoDoes = ({ name, who_does, imageWhoDoes, whoDoesTitle }) => {
  return (
    <section className="block-sec about-us">
      <div
        className="background bg-img pt-120 pb-0 parallaxie"
        style={{ backgroundImage: `url(${imageWhoDoes[0]?.photo})` }}
        data-overlay-dark="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
            </div>
            <div className="col-lg-9 offset-lg-3">
              <div className="testim-box">
                <div className="head-box sec-head mb-0">
                  <h3 className="ls1 mb-30 color-font">
                    {whoDoesTitle}
                  </h3>
                </div>
                <div className="item">
                  <p>
                    {who_does}
                  </p>
                  <p>
                    <a href={`https://instagram.com/${name}`} target="_blank">@{name}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoDoes;
