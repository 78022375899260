import React from 'react'
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";

const Insights = ({ mkInsights }) => {
  React.useEffect(() => {
  },[])
    return (
      <section className="insights section-padding">
        <div className="container">
          <div className="sub-title">
            <div className="sec-head">
              <h3 className="text-center ls1 mb-30 color-font">Dados Analíticos do Instagram</h3>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-3 line-color">
              <div className="sec-head-2 text-center">
                <h6 className="ls1 color-font">
                  <strong>Análise de Feed</strong>
                </h6>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className={`icon far fa-images`}></span>
                  <CountUp redraw={true} end={mkInsights.posts_total} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Posts
                  </span>
                </h3>
                
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className={`icon far fa-thumbs-up`}></span>
                  <CountUp redraw={true} end={mkInsights.posts_likes} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Curtidas
                  </span>
                </h3>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className={`icon far fa-comments`}></span>
                  <CountUp redraw={true} end={mkInsights.posts_comments} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Comentários
                  </span>
                </h3>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className={`icon fas fa-eye`}></span>
                  <CountUp redraw={true} end={mkInsights.posts_views} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Visualizações
                  </span>
                </h3>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className={`icon fas fa-users`}></span>
                  <CountUp redraw={true} end={mkInsights.posts_reach} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Alcance
                  </span>
                </h3>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className={`icon far fa-bookmark`}></span>
                  <CountUp redraw={true} end={mkInsights.posts_saves} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Salvos
                  </span>
                </h3>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className="icon fas fa-info-circle"></span>
                  <CountUp redraw={true} end={mkInsights.posts_engagement} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Interações
                  </span>
                </h3>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className="icon fas fa-percent"></span>
                  <CountUp redraw={true} end={((mkInsights.posts_views + mkInsights.posts_reach) / mkInsights.posts_engagement)} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Engajamento/Alcance
                  </span>
                </h3>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className="icon fas fa-percent"></span>
                  <CountUp redraw={true} end={((mkInsights.posts_engagement / mkInsights.followers) * 100)} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Engajamento/Seguidores
                  </span>
                </h3>
              </div>
            </div>
            
            <div className="col-md-3 line-color">
              <div className="sec-head-2 text-center">
                <h6 className="ls1 color-font">
                  <strong>Análise de Reels</strong>
                </h6>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className={`icon fas fa-video`}></span>
                  <CountUp redraw={true} end={mkInsights.videos_total} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Vídeos
                  </span>
                </h3>
                
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className={`icon far fa-thumbs-up`}></span>
                  <CountUp redraw={true} end={mkInsights.videos_likes} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Curtidas
                  </span>
                </h3>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className={`icon far fa-comments`}></span>
                  <CountUp redraw={true} end={mkInsights.videos_comments} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Comentários
                  </span>
                </h3>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className={`icon fas fa-eye`}></span>
                  <CountUp redraw={true} end={mkInsights.videos_plays} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Reproduções
                  </span>
                </h3>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className={`icon fas fa-users`}></span>
                  <CountUp redraw={true} end={mkInsights.videos_reach} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Alcance
                  </span>
                </h3>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className={`icon far fa-bookmark`}></span>
                  <CountUp redraw={true} end={mkInsights.videos_saves} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Salvos
                  </span>
                </h3>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className="icon fas fa-share-square"></span>
                  <CountUp redraw={true} end={mkInsights.videos_shares} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Compartilhamentos
                  </span>
                </h3>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className="icon fas fa-info-circle"></span>
                  <CountUp redraw={true} end={mkInsights.videos_total_interactions} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Interações
                  </span>
                </h3>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className="icon fas fa-percent"></span>
                  <CountUp redraw={true} end={((mkInsights.videos_total_interactions / mkInsights.videos_reach) * 100)} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Engajamento/Alcance
                  </span>
                </h3>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className="icon fas fa-percent"></span>
                  <CountUp redraw={true} end={((mkInsights.videos_total_interactions / mkInsights.followers) * 100)} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Engajamento/Seguidores
                  </span>
                </h3>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <h3 className="">
                  <span className="icon fas fa-percent"></span>
                  <CountUp redraw={true} end={((mkInsights.videos_plays / mkInsights.videos_reach) * 100)} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <span className="txt color-font" >
                    Taxa de Retenção
                  </span>
                </h3>
              </div>
            </div>

            <div className="col-md-3">
              <div className="col-md-12 line-color-2">
                <div className="sec-head-2 text-center">
                  <h6 className="ls1 color-font">
                    <strong>Análise de Story</strong>
                  </h6>
                </div>
                <div className="item no-bord sm-mb10 text-center ">
                  <h3 className="">
                    <span className={`icon fas fa-history`}></span>
                    <CountUp redraw={true} end={mkInsights.stories_total} duration="3">
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span className="count" ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <span className="txt color-font" >
                      Stories
                    </span>
                  </h3>
                  
                </div>
                <div className="item no-bord sm-mb10 text-center ">
                  <h3 className="">
                    <span className={`icon fas fa-eye`}></span>
                    <CountUp redraw={true} end={mkInsights.stories_impressions} duration="3">
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span className="count" ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <span className="txt color-font" >
                      Visualizações
                    </span>
                  </h3>
                </div>
                <div className="item no-bord sm-mb10 text-center ">
                  <h3 className="">
                    <span className={`icon fas fa-users`}></span>
                    <CountUp redraw={true} end={mkInsights.stories_reach} duration="3">
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span className="count" ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <span className="txt color-font" >
                      Alcance
                    </span>
                  </h3>
                </div>
                <div className="item no-bord sm-mb10 text-center ">
                  <h3 className="">
                  <span className="icon fas fa-info-circle"></span>
                    <CountUp redraw={true} end={mkInsights.stories_total_interactions} duration="3">
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span className="count" ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <span className="txt color-font" >
                      Interações
                    </span>
                  </h3>
                </div>
                <div className="item no-bord sm-mb10 text-center ">
                  <h3 className="">
                    <span className={`icon fas fa-percent`}></span>
                    <CountUp redraw={true} end={((mkInsights.stories_total_interactions / mkInsights.stories_reach) * 100)} duration="3">
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span className="count" ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <span className="txt color-font" >
                      Engajamento/Alcance
                    </span>
                  </h3>
                </div>
              </div>
              <div className="col-md-12 line-color-2">
                <div className="sec-head-2 text-center">
                  <h6 className="ls1 color-font">
                    <strong>Consolidado</strong>
                  </h6>
                </div>
                <div className="item no-bord sm-mb10 text-center ">
                  <h3 className="">
                    <span className={`icon fas fa-users`}></span>
                    <CountUp redraw={true} end={mkInsights.posts_reach + mkInsights.videos_reach + mkInsights.stories_reach} duration="3">
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span className="count" ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <span className="txt color-font" >
                      Total de Alcance
                    </span>
                  </h3>
                </div>
                <div className="item no-bord sm-mb10 text-center ">
                  <h3 className="">
                    <span className={`icon fas fa-eye`}></span>
                    <CountUp redraw={true} end={mkInsights.posts_views + mkInsights.videos_plays + mkInsights.stories_impressions} duration="3">
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span className="count" ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  </h3>
                  <h3>
                    <span className="txt color-font" >
                      Total de impressões,<br />
                      reproduções e visualizações
                    </span>
                  </h3>
                </div>
                <div className="item no-bord sm-mb10 text-center ">
                  <h3 className="">
                    <span className={`icon fas fa-percent`}></span>
                    <CountUp redraw={true} end={((mkInsights.posts_engagement + mkInsights.videos_total_interactions) / (mkInsights.posts_views + mkInsights.videos_plays + mkInsights.stories_impressions)) * 100} duration="3">
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span className="count" ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <span className="txt color-font" >
                      Engajamento/Alcance
                    </span>
                  </h3>
                </div>
                <div className="item no-bord sm-mb10 text-center ">
                  <h3 className="">
                    <span className={`icon fas fa-percent`}></span>
                    <CountUp redraw={true} end={((mkInsights.posts_engagement + mkInsights.videos_total_interactions) / mkInsights.followers) * 100} duration="3">
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span className="count" ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <span className="txt color-font" >
                      Engajamento/Seguidores
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12">
              <div className="sec-head">
                * Dados dos reels e feed dos últimos 30 dias
                <br/>
                * Dados dos Stories das últimas 24 horas
                <br/>
                * Dados obtidos pela API oficial do Facebook/Instagram
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Insights