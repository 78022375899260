import React from "react";
import ApiMidiaKit from '../../services/midiaKitServices';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


const ContactForm = ({ name, user, socialProfile, contactEmail }) => {
  
  const handleSubmit = async () => {
    const MySwal = withReactContent(Swal);

    if (username === '' || nome === '' || empresa === '' || email === '' || telefone === '' || mensagem === ''){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        html: 'Por favor preencha todos os campos do formulário!',
      })
      return;
    }

    const res = await ApiMidiaKit.sendMail(username, nome, empresa, email, telefone, mensagem, contactEmail);
    if (res.message){
      Swal.fire({
        icon: 'success',
        html: 'Seu email foi enviado com sucesso!<br/>Em breve entraremos em contato!'
      });
      setNome('');
      setEmpresa('');
      setEmail('');
      setTelefone('');
      setMensagem('');
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        html: 'Houve um erro na tentativa de enviar seu email!<br> Tente novamente mais tarde!',
        footer: 'Ou tente enviar o email clicando <a style="margin-left:5px;font-weight:bold; color:#700000" href="mailto:' + user.email + '"> aqui</a>!'
      });
    }
  }

  const [username, setUsername] = React.useState(socialProfile.social_profile_username);
  const [nome, setNome] = React.useState('');
  const [empresa, setEmpresa] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [telefone, setTelefone] = React.useState('');
  const [mensagem, setMensagem] = React.useState('');

  return (
    <section className="contact section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="form md-mb50">
              <h3 className="ls1 mb-3">Vamos conversar?</h3>

              <form onSubmit={handleSubmit}>
                <div className="messages"></div>

                <div className="controls">
                  <div className="form-group">
                    <input
                      type="text"
                      required
                      placeholder="Seu nome para contato"
                      className="form-control"
                      onChange={event => setNome(event.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      required
                      placeholder="Nome da Empresa"
                      className="form-control"
                      onChange={event => setEmpresa(event.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="email"
                      placeholder="Digite o seu email"
                      required
                      className="form-control"
                      onChange={event => setEmail(event.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="phone"
                      required
                      placeholder="Digite seu telefone no formato 11 99999-9999"
                      className="form-control"
                      onChange={event => setTelefone(event.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <textarea
                      type="textarea"
                      required
                      placeholder="Digite a sua mensagem"
                      className="form-control"
                      onChange={event => setMensagem(event.target.value)}
                    ></textarea>
                  </div>

                  <button type="button" className="butn bord" onClick={handleSubmit}>
                    <span>Enviar Mensagem</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div className="cont-info">
              <h4 className="fw-700 mb-50">Dados de Contato.</h4>
              <h3 className="wow" data-splitting>
                Vamos conversar
              </h3>
              <br/>
              <div className="item mb-40">
                <h5>
                  <a href={`mailto:${contactEmail ?? user.email}`}><i className="far fa-envelope"></i> {contactEmail ?? user.email}</a>
                </h5>
                <h5>
                  <a href={`https://instagram.com/${socialProfile.social_profile_username}`} >
                    <i className="fab fa-instagram"></i> {socialProfile.social_profile_username}
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
