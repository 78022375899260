import React from "react";

const LoadingScreen = () => {
  return (
    <>
      <div className="loading">
        <span>C</span>
        <span>A</span>
        <span>R</span>
        <span>R</span>
        <span>E</span>
        <span>G</span>
        <span>A</span>
        <span>N</span>
        <span>D</span>
        <span>O</span>
      </div>
      <div id="preloader"></div>
    </>
  );
};

export default LoadingScreen;
