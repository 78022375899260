import React from 'react';
import { Router } from 'react-router-dom';
import Routes from './routes';
import history from './history';
import LoadingScreen from "./components/Loading-Screen/loading-screen ";
import useScript from "./hooks/useScript";

function App() {

  return (
    <>
      <LoadingScreen />
      <Router history={history}>
        <Routes />
      </Router>
      
    </>
  );
}

export default App;
