const BASE_API = "https://api.academiadamatilha.com.br/api/";
//const BASE_API = "http://localhost:8000/api/";

export default {
  getAllMidiaKit: async () => {
    try {
      const req = await fetch(`${BASE_API}public-all-midia-kit/` , {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  getMidiaKitData: async (name) => {
    try {
      const req = await fetch(`${BASE_API}public-data-midia-kit/` + name, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      });
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  sendMail: async (name, nome, empresa, email, telefone, mensagem, contact_email) => {
    try {
      const req = await fetch(`${BASE_API}send-mail`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: name, nome: nome, empresa: empresa, email: email, telefone: telefone, mensagem: mensagem, contact_email: contact_email })
      });
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  }
 
};