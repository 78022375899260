import React from 'react';

function littleNumber(totalNumber,unity) {

  let rounded = Math.round(totalNumber) / unity;

  return rounded.toFixed(1);
  
}

function measurement(totalNumber) {
  
  if (totalNumber > 999999999999) {
    return littleNumber(totalNumber, 1000000000000) + ' Tr';
  }

  if (totalNumber > 999999999) {
    return littleNumber(totalNumber, 1000000000) + ' Bi';
  }

  if(totalNumber > 999999){
    return littleNumber(totalNumber, 1000000) + ' Mi';
  }

  if (totalNumber > 999) {
    return littleNumber(totalNumber, 1000) + ' Mil';
  }

  return totalNumber;

}

const NumberFollowers = ({
  number,
  ...restProps
}) => {

  return( 
    <span>
      { measurement(number) } 
    </span>
  )
}

export default NumberFollowers;
