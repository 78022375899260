import React from 'react'

const Footer = ({ hideBGCOLOR }) => {
  
  const d = new Date();
  let year = d.getFullYear();

  return (
    <footer className={`${!hideBGCOLOR ? 'sub-bg' : ''}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="item">
              <div className="copy-right logo">
                <table>
                  <tbody>
                    <tr>
                      <td><a href="https://matilhabrasil.com" target="_blank"><img src="/img/logo-matilha-brasil.png" alt="" align="letf" /></a></td>
                      <td><p>
                        © {year} Matilha Brasil. Desenvolvido por
                        <a href="https://bleepdigital.com.br" target="_blank">Bleep Digital</a>.
                      </p></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer