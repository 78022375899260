import React from "react";
import { Link } from "react-scroll";

const NavbarArch = ({ navbarRef, theme, menu, roles}) => {
  const handleDropdown = (e) => {
    // getSiblings(e.target.parentElement)
    //   .filter((item) => item.classList.contains("show"))
    //   .map((item) => {
    //     item.classList.remove("show");
    //     if (item.childNodes) {
    //       if (item.childNodes[0]) {
    //         item.childNodes[0].setAttribute("aria-expanded", false);
    //       }
    //       if (item.childNodes[1]) {
    //         item.childNodes[1].classList.remove("show");
    //       } 
    //     }
    //   });
    e.target.parentElement.classList.toggle("show");
    e.target.setAttribute("aria-expanded", true);
    e.target.parentElement.childNodes[1].classList.toggle("show");
  };

  const handleMobileDropdown = (e) => {
    document
      .getElementById("navbarSupportedContent")
      .classList.toggle("show-with-trans");
  };
  return (
    <nav className="navbar navbar-expand-lg" ref={navbarRef}>
      <div className="container">
        <a className="logo" href="#">
          {theme ? (
            theme === "themeL" ? (
              <img src="/img/logo-academia-da-matilha.png" alt="logo" />
            ) : (
                <img src="/img/logo-academia-da-matilha.png" alt="logo" />
            )
          ) : (
              <img src="/img/logo-academia-da-matilha.png" alt="logo" />
          )}
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          onClick={handleMobileDropdown}
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-bar">
            <i className="fas fa-bars"></i>
          </span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown" onClick={handleDropdown}>
              <Link
                className="nav-link"
                to="home"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                Home
              </Link>
            </li>
            {menu.find(element => element === roles.title_about) &&
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  {roles.title_about}
                </Link>
              </li>
            }
            {menu.find(element => element === roles.title_who_does) &&
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="about-us"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  {roles.title_who_does}
                </Link>
              </li>
            }
            {menu.find(element => element === 'Público') &&
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="audience"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  Público
                </Link>
              </li>
            }
            {menu.find(element => element === 'Análise') &&
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="insights"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  Análise
                </Link>
              </li>
            }
            {menu.find(element => element === roles.title_brands) &&
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="cases"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  {roles.title_brands}
                </Link>
              </li>
            }
            {menu.find(element => element === roles.title_partners) &&
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="clients"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  {roles.title_partners}
                </Link>
              </li>
            }
            <li className="nav-item">
              <Link
                className="nav-link"
                to="contact"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                Contato
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavbarArch;
