import React, { useState, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom';
import ApiMidiaKit from '../services/midiaKitServices'
import DarkTheme from "../layouts/Dark";
import NavbarArch from "../components/Navbar-arch/navbar-arch";
import Home from "../components/Home/home";
import About from "../components/About/about";
import WhoDoes from "../components/WhoDoes/who-does";
import Audience from "../components/Audience/audience";
import Insights from "../components/Insights/insights";
import Cases from "../components/Cases/cases";
import Partners from "../components/Partners/partners";
import ContactForm from "../components/Contact-form/contact-form";
import Footer from "../components/Footer/footer";

function MidiaKit() {

  const history = useHistory();
  const { name } = useParams();

  const [audienceAge, setAudienceAge] = useState([]);
  const [audienceCityPercent, setAudienceCityPercent] = useState([]);
  const [audienceSex, setAudienceSex] = useState([]);
  const [brands, setBrands] = useState([]);
  const [contactEmail, setContactEmail] = useState('');
  const [coverImage, setCoverImage] = useState([]);
  const [description, setDescription] = useState('');
  const [frontImage, setFrontImage] = useState([]);
  const [imageAbout, setImageAbout] = useState([]);
  const [imageWhoDoes, setImageWhoDoes] = useState([]);
  const [insights, setInsights] = useState([]);
  const [pages, setPages] = useState([]);
  const [partners, setPartners] = useState([]);
  const [profile, setProfile] = useState('');
  const [screenWidth, setScreenWidth] = useState([]);
  const [screenHeight, setScreenHeight] = useState([]);
  const [socialProfile, setSocialProfile] = useState([]);
  const [user, setUser] = useState([]);
  const [whoDoes, setWhoDoes] = useState('');
  const [roles, setRoles] = useState('');

  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  const getMidiaKit = async (name) => {

    const midiakit = await ApiMidiaKit.getMidiaKitData(name);

    if (midiakit.error) {
      history.push("/");
    } else {

      let menu = ['Início'];
      setProfile(midiakit.mk.name);
      setContactEmail(midiakit.mk.contact_email);
      setSocialProfile(midiakit.socialProfile);
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
      setUser(midiakit.user);
      setRoles(midiakit.roles);

      if (midiakit.mkPhoto !== undefined) {
        setCoverImage(midiakit.mkPhoto.filter(data => (data.type === 'coverImage')));
        setFrontImage(midiakit.mkPhoto.filter(data => (data.type === 'frontImage')));
        setImageAbout(midiakit.mkPhoto.filter(data => (data.type === 'imageAbout')));
        setImageWhoDoes(midiakit.mkPhoto.filter(data => (data.type === 'imageWhoDoes')));
      }

      if (midiakit.mk.description !== undefined) {
        if (midiakit.mk.description !== null) {
          if (midiakit.mk.description.length > 0) {
            menu = [...menu, midiakit.roles.title_about];
            setDescription(midiakit.mk.description);
          }
        }
      }

      if (midiakit.mk.who_does !== undefined) {
        if (midiakit.mk.who_does !== null) {
          if (midiakit.mk.who_does.length > 0) {
            menu = [...menu, midiakit.roles.title_who_does];
            setWhoDoes(midiakit.mk.who_does);
          }
        }
      }

      if (midiakit.mkInsights !== undefined && midiakit.mk.show_analytics === 1) {
        if (midiakit.mkInsights !== null) {
          menu = [...menu, 'Análise'];
          menu = [...menu, 'Público']
          setInsights(midiakit.mkInsights);
        }
      }

      if (midiakit.mkAudienceAge !== undefined && midiakit.mk.show_analytics === 1) {
        if (midiakit.mkAudienceAge !== null) {
          menu = [...menu, 'Público'];
          setAudienceAge(midiakit.mkAudienceAge);
          setAudienceSex(midiakit.mkAudienceSex);
          setAudienceCityPercent(midiakit.mkAudienceCityPercent);
        }
      }

      if (midiakit.mkBrand !== undefined) {
        if (midiakit.mkBrand.length > 0) {
          menu = [...menu, midiakit.roles.title_brands];
          setBrands(midiakit.mkBrand);
        }
      }

      if (midiakit.mkPartner !== undefined) {
        if (midiakit.mkPartner.length > 0) {
          menu = [...menu, midiakit.roles.title_partners];
          setPartners(midiakit.mkPartner);
        }
      }

      menu = [...menu, 'Contato'];
      setPages(menu);
    }
  }
  
  useEffect(() => {
    getMidiaKit(name);
  }, [])

  return (

    <DarkTheme>

      {pages &&
        <NavbarArch nr={navbarRef} lr={logoRef} menu={pages} roles={ roles }/>
      }

      {pages.find(element => element === 'Início') &&
        <Home
          coverImage={coverImage} 
          frontImage={frontImage[0]?.photo}
          name={profile}
          screenWidth={screenWidth}
          screenHeight={screenHeight}
        />
      }

      {pages.find(element => element === roles.title_about) &&
        <About
          name={name}
          description={description}
          imageAbout={imageAbout[0]?.photo}
          followers={insights.followers} 
          aboutTitle={roles.title_about}
        />
      }

      {pages.find(element => element === roles.title_who_does) &&
        <WhoDoes
          name={name}
          who_does={whoDoes}
          imageWhoDoes={imageWhoDoes} 
          whoDoesTitle={roles.title_who_does}
        />
      }

      {pages.find(element => element === 'Público') &&
        <Audience
          mkAudienceAge={audienceAge}
          mkAudienceSex={audienceSex}
          mkAudienceCityPercent={audienceCityPercent}
          screenWidth={screenWidth}
          name={name}
        />
      }

      {pages.find(element => element === 'Análise') &&
        <Insights mkInsights={insights} />
      }

      {pages.find(element => element === roles.title_brands) &&
        <Cases 
          mkBrand={brands} 
          brandTitle={roles.title_brands} 
          />
      }

      {pages.find(element => element === roles.title_partners) &&
        <Partners
          theme="dark"
          mkPartner={partners}  
          partnerTitle={roles.title_partners} 
          />
      }

      {pages.find(element => element === 'Contato') &&
        <ContactForm
          name={profile}
          user={user}
          socialProfile={socialProfile}
          id="contact"
          contactEmail={contactEmail}
        />
      }

      {pages &&
        <Footer />
      }
    </DarkTheme>
  );
};

export default MidiaKit;
