import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NoMidiaKit from './pages/NoMidiaKit';
import MidiaKit from './pages/MidiaKit';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={NoMidiaKit} />
      <Route exact path="/:name" component={MidiaKit} />
    </Switch>
  );
}