import React from 'react'

const DarkTheme = ({ children }) => {
  return (
    <>
      <head>
        <link href="https://fonts.googleapis.com/css2?family=Autour+One&display=swap" rel="stylesheet"></link>
        <link rel="stylesheet" href="/css/dark.css" />
      </head>
      {children}
      
    </>
  );
};

export default DarkTheme