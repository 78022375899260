import React from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const Public = ({ mkAudienceAge, mkAudienceSex, mkAudienceCityPercent, screenWidth, name }) => {

  const data = [
    { name: "Mulheres", value: mkAudienceSex.women_qtd },
    { name: "Homens", value: mkAudienceSex.men_qtd }
  ];

  const COLORS = ['#ee4b60','#ff6941'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  React.useEffect(() => {
    setTimeout(() => {
      let template1 = document.createElement("div");
      template1.classList.add("div-tooltip-tit");
      document.body.appendChild(template1);
      let template2 = document.createElement("div");
      template2.classList.add("div-tooltip-sub");
      document.body.appendChild(template2);
      document.querySelectorAll("[data-tooltip-tit]").forEach((item) => {
        template1 = document.querySelector(".div-tooltip-tit");
        item.addEventListener("mouseover", () => {
          template1.innerText = item.getAttribute("data-tooltip-tit");
        });
        item.addEventListener("mousemove", (e) => {
          //fadeIn(template1, 800);
          template1.style.top = e.pageY + 10 + "px";
          template1.style.left = e.pageX + 20 + "px";
          template1.style.padding = "0px 10px";
        });
        item.addEventListener("mouseleave", () => {
          //fadeOut(template1, 800);
          template1.style.padding = 0;
        });
      });
      document.querySelectorAll("[data-tooltip-sub]").forEach((item) => {
        template2 = document.querySelector(".div-tooltip-sub");
        item.addEventListener("mouseover", () => {
          template2.innerText = item.getAttribute("data-tooltip-sub");
        });
        item.addEventListener("mousemove", (e) => {
          //fadeIn(template2, 800);
          template2.style.top = e.pageY - 15 + "px";
          template2.style.left = e.pageX + 30 + "px";
          template2.style.padding = "5px 10px";
        });
        item.addEventListener("mouseleave", () => {
          //fadeOut(template2, 800);
          template2.style.padding = 0;
        });
      });
  
      let teamSection = document.querySelector(".team-crv");
      if (teamSection) {
        window.addEventListener("scroll", () => {
          document
            .querySelectorAll(".skill-progress .progres")
            .forEach((item) => {
              let myVal = item.getAttribute("data-value");
              if (window.pageYOffset > teamSection.offsetTop - 200) {
                item.style.width = myVal;
              }
            });
        });
      }
    }, 1000);

  }, []);

  return (
    <div className="audience team-crv section-padding">
      <div className="container">
        <div className="sub-title">
          <div className="sec-head">
            <h3 className="text-center ls1 mb-30 color-font">Público & Audiência</h3>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-3 line-color">
            <div className="sec-head-2 text-center">
              <h6 className="ls1 color-font">
                <strong>Por idade</strong>
              </h6>
            </div>
            <div className="item no-bord sm-mb10">
              <br />
              <div className="content wow fadeInUp md-mb30" data-wow-delay=".5s">
                <div className="skills-box">
                  {mkAudienceAge.map((audience) => (
                    <div className="skill-item" key={audience.age}>
                      <h5 className="fz-14 mb-15">{audience.age} anos</h5>
                      <div className="skill-progress">
                        <div className="progres" data-value={ audience.qtd }></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <br />
            </div>
          </div>
          <div className="col-md-3 line-color">
            <div className="sec-head-2 text-center">
              <h6 className="ls1 color-font">
                <strong>Por cidades</strong>
              </h6>
            </div>
            <div className="item no-bord sm-mb10">
            <br />
              <div className="content wow fadeInUp md-mb30" data-wow-delay=".5s">
                <div className="skills-box">
                  { mkAudienceCityPercent.map((audience) => (
                    <div className="skill-item" key={audience.city}>
                      <h5 className="fz-14 mb-15">{audience.city}</h5>
                      <div className="skill-progress">
                        <div className="progres" data-value={ audience.percent }></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <br />
            </div>
          </div>
          <div className="col-md-3 line-color">
              <div className="sec-head-2 text-center">
                <h6 className="ls1 color-font">
                  <strong>Por Gênero</strong>
                </h6>
              </div>
              <div className="item no-bord sm-mb10 text-center ">
                <div className="img-box">
                  <div className="row">
                    <div className="col-sm-12 toright">
                      <div className="sub-title">
                        <span></span>
                      </div>
                      <div className="full-width">
                        <PieChart 
                          width={screenWidth > 920 ? 315 : 300} 
                          height={screenWidth > 920 ? 315 : 300}
                          >
                          <Legend />
                          <Pie
                            data={data}
                            cx={130}
                            cy={130}
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={screenWidth > 920 ? 130 : 130}
                            dataKey="value"
                          >
                            {data.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <Tooltip/>
                        </PieChart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
        </div>
      </div>
    </div>
  );
};

export default Public;
