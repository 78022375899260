import React from "react";
import Split from "../Split";

const Partners = ({ theme, mkPartner, partnerTitle}) => {
  
  return (
    <section className="clients section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 valign md-mb50">
            <div className="sec-head mb-0">
              <h3 className="ls1 mb-30 color-font">{partnerTitle}</h3>
              <p>
              </p>
            </div>
          </div>
          <div className="col-lg-8">
            <div>
              <div className="row bord">
                {mkPartner.map((item, index) => (
                  <div key={item.id} className="col-md-3 col-6 brands">
                    <div
                      className="item wow fadeIn"
                      data-wow-delay={`0.${index * 2}s`}
                    >
                      <div className="img">
                        <img src={item.photo} alt={item.username} />
                          <a
                            href={`https://instagram.com/${item.username}`}
                            className="link"
                            target="_blank"
                          >
                            {item.name}
                          </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
