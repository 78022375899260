import React from 'react'

const NoMidiaKit = () => {
  return (
    <header className="slider-st valign position-re">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 valign">
            <div className="cont md-mb50">
              <div className="sub-title mb-5">
              </div>
              <h1 className="mb-10 fw-600">Mídia Kit Indisponível</h1>
              <p>
                Ops! Este Mídia Kit não existe ou não está disponível.<br/>
                Verifique com o influenciador se o link informado está correto.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="img">
              <img src="/img/404-dog.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="line bottom left"></div>
    </header>
  );
};

export default NoMidiaKit