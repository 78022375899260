import React, { useState, useEffect } from "react";

const Home = ({ coverImage, frontImage, name, screenWidth, screenHeight }) => {
  const [sliceLength, setSliceLength] = useState(coverImage.length);

  const d = new Date();
  let year = d.getFullYear();

  function calcBackgroundBlock(screenWidth, screenHeight, coverImage){
    if(screenWidth < screenHeight){
      if (screenWidth <= 920){
        setSliceLength('2');
      }else{
        setSliceLength(coverImage.length);
      }
    }
  }

  useEffect(() => {
    calcBackgroundBlock(screenWidth, screenHeight, coverImage);
  }, [])

  return (
    <div className="home slider-stwo valign position-re">
      <div id="grid">
        {coverImage.slice(0, sliceLength).map((image,index)=>(
          <div key={image.id}
            className="background-block"
            style={{ backgroundImage: `url(${image.photo})` }}
            ></div>
        ))}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 valign">
            <div className="cont">
              <div className="sub-title mb-5">
                <h3 className="wow animated">Midia Kit { year }</h3>
              </div>
              <h1 className="mb-10 fw-600 wow animated">{ name }</h1>
            </div>
          </div>
          <div className="col-lg-4">
            <div
              className="img wow fadeInUp"
              data-wow-delay=".6s"
            >
              {frontImage &&
                <img src={decodeURI(frontImage)} alt={name} />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
