import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Cases extends React.Component {
  constructor(props) {
    super(props);
  }
  renderArrows = () => {
    return (
      <div className="arrows">
        <div className="container">
          <div
            onClick={() => this.slider.slickNext()}
            className="next cursor-pointer"
          >
            <img src="/img/patinha-2.png" width="50" alt="patinha"/>
          </div>
          <div
            onClick={() => this.slider.slickPrev()}
            className="prev cursor-pointer"
          >
            <img src="/img/patinha-3.png" width="50" alt="patinha"/>
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <section
        className={`cases testimonials ${
          this.props.withIMG
            ? "section-padding bg-img"
            : this.props.withCOLOR
            ? "section-padding back-color"
            : this.props.noPadding ? ""
            : "section-padding"
        } ${this.props.classText ? this.props.classText : ""}`}
        style={{
          backgroundImage: `${
            this.props.withIMG ? "url(/img/testim-img.jpg)" : "none"
          }`,
        }}
      >
        <div className="container position-re">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="sec-head  text-center">
                <h3 className="ls1 mb-30">
                  { this.props.brandTitle}
                </h3>
              </div>
            </div>
          </div>
          <div className="row wow fadeInUp" data-wow-delay=".5s">
            <div className="col-lg-12">
              <Slider
                className="slic-item"
                {...{
                  ref: (c) => (this.slider = c),
                  dots: false,
                  infinite: true,
                  arrows: true,
                  centerMode: false,
                  autoplay: false,
                  rows: 1,
                  slidesToScroll: 1,
                  slidesToShow: 1,
                  responsive: [
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 1,
                        centerMode: false,
                      },
                    },
                    {
                      breakpoint: 767,
                      settings: {
                        slidesToShow: 1,
                        centerMode: false,
                      },
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 1,
                        centerMode: false,
                      },
                    },
                  ],
                }}
              >
                {this.props.mkBrand.map((brand) => (
                  <div className="item" key={brand.id}>
                    <div className="info valign">
                      <div className="cont">
                        <div className="author">
                          <div className="img">
                            <img src={brand.photo} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="fz-20 fw-200">
                      <h3 className="wow mb-20">{brand.title}</h3>
                      {brand.description}
                    </p>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          {this.renderArrows()}
        </div>
      </section>
    );
  }
}

export default Cases;
