import React from "react";
import DarkTheme from "../layouts/Dark";
import NoMidiaKit from "../components/NoMidiaKit/NoMidiaKit";

const ErrorPage = () => {
  return (
    <DarkTheme>
      <NoMidiaKit />
    </DarkTheme>
  );
};

export default ErrorPage;
